import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'CIVICTECH.TV', // e.g: 'Name | Developer'
  lang: 'jp', // e.g: en, es, fr, jp
  description: 'Event planning, filming, and video distribution.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, we are',
  name: 'CIVICTECH.TV',
  subtitle: 'Event planning, filming, and video distribution.',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: 'はじめまして。',
  paragraphTwo: 'わたしたちCIVICTECH.TVは、インターネット等を利用した映像の配信、放送並びにそれらの企画及びコンサルティング等を行っています。',
  paragraphThree: '「行動する」ことを何より大切に考えており、考えるより先に動きます。もちろん、人並み以上に慎重なので熟慮の上で行動に移しているのですが、きっとそうは見えていないことでしょう。自ら行動し、創意工夫と最適化の末に得た生きた知見を、みなさまにフィードバックしていけたらと考えています。',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: 'スナック恭子 | YouTube',
    info: '「スナック恭子」は、毎回さまざまなテーマに沿ってゲストと対談するトーク番組です。',
    info2: '「DX」をテーマに14人のお客様をお招きした第1シーズンが完結。新しく始まった第2シーズンのテーマは『未来』です。さらに幅広い業界、例えば教育とかそういったところで活躍される方々をお客様としてお招きしたいなと考えています。基本的に、隔週木曜の20時から、新しいコンテンツの配信並びにプレミア公開しております。',
    url: 'https://www.youtube.com/%E3%82%B9%E3%83%8A%E3%83%83%E3%82%AF%E6%81%AD%E5%AD%90',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2.png',
    title: 'Cテレ2255 | Facebook Live',
    info: 'Civictechを中心に、ITやDXにまつわるNEWSをとりあげる番組です。色々試行錯誤しながら、スタイルを決めようとしています。',
    info2: '不定期ですが、平日の夜22:55から30分間Facebook Liveにて生配信しています。配信後に、高画質版をYouTubeに、音声のみ再編集版をPodcastにて配信しています。',
    url: 'https://www.facebook.com/civictech.tv/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3.jpg',
    title: '撮影、映像配信承ります',
    info: '各種オンラインイベントの企画、運営。ならびに、出張撮影や映像配信を承ります。',
    info2: 'ありきたりの定型のお仕事でも構いませんが、わたしたちの強みはその企画力と、実行力にあると考えています。ぜひ、企画段階からお手伝いさせていただくと、喜びます。お気軽にお問い合わせください。',
    url: 'https://www.facebook.com/civictech.tv/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: 'お問合せ',
  email: 'info{atmark}civictech.tv',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/civictech.tv/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
